import React from "react";
// import ScrollToTopButton from "../../components/ScrollToTop";
import ShowContainer from "../../containers/ShowContainer";
import showsData from '../../data/showsData.json';

const Cincomediantes = () => {
    const shows = showsData;
    const currentShow = shows[1];

    const getRelatedShows = () => {
        const currentShowIndex = shows.findIndex((show) => show.id === currentShow.id);

        const relatedShows = shows
            .filter((show, index) => index !== currentShowIndex)
            .slice(0, 3);

        return relatedShows;
    };

    const relatedShows = getRelatedShows();

    return (
        <main className="wrapper">
            {/* <ScrollToTopButton/> */}
            <ShowContainer
                img={currentShow.img}
                alt={`${currentShow.name}`}
                title={`${currentShow.name}`}
                description={`${currentShow.description}`}
                audience={`${currentShow.audience}`}
                duration={`${currentShow.duration}`}
            
                relatedShows={relatedShows}
            />
        </main>
    );
};
export default Cincomediantes;
