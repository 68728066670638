import React, { useState } from "react";
// import ScrollToTopButton from "../components/ScrollToTop";
import nextShowsData from "../data/nextShowsData.json"
import OfferCard from "../components/OfferCard";

// Date format in 'nextShowsData.json' YYYY-MM-DD

const OfferContainer = () => {
    const [filterData, setFilterData] = useState('')

    const handleFilterMonthChange = (month) => {
        setFilterData(month);
    };

    const today = new Date();
    const filteredData = nextShowsData.filter(data => data.month === filterData);
    // const uniqueMonth = filteredData.length > 0 ? filteredData[0].month : '';
    const uniqueMonths = [...new Set(nextShowsData.map(data => data.month))];
    const availableMonths = uniqueMonths.filter(month => {
        return nextShowsData.some(data => {
            const eventDate = new Date(data.date);
            return data.month === month && eventDate >= today;
        });
    });

    return (
        <main className="wrapper">
            <section className="project-details bg-dark-200 pb-5 " id="event_offer">
                {/* <ScrollToTopButton /> */}
                <div className="container">
                    <div className="row">
                        <div className="row offer_image">
                            <img src="/images/offer.jpg" alt="Imagen de la oferta de 5inco" />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 " id="sidebar_month">
                                <div className="sidebar_offer" >
                                    <div className="service-information">
                                        <ul>
                                            {availableMonths.map(month => (
                                                <li key={month}>
                                                    <button id="button-offer-month" className={filterData === month ? 'active' : ''} onClick={() => handleFilterMonthChange(month)}>{month}</button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-8 col-md-8">
                                <div className="project-details_inner">
                                    <div className="post-header text-white pt-2">
                                        <h3 className="post-info pb-3">Calendario de Eventos</h3>
                                        <h5 className="post-info-sub pb-3">Sin shows</h5>
                                        {/* MUESTRA EL MES SELECCIONADO */}
                                        {/* <h3 className="post-title pb-2 text-uppercase text-gray ">{uniqueMonth}</h3> */}
                                    </div>

                                    {
                                        filteredData.map(data => (

                                            <OfferCard
                                                key={data.id}
                                                data={data} />
                                        ))
                                    }
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </section>
        </main>
    )
};
export default OfferContainer;