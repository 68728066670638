import React, { useState } from "react";
// import ScrollToTopButton from "../components/ScrollToTop";
import cincoGalleryData from "../data/cincoGalleryData.json"
import GalleryCard from "../components/GalleryCard";

const CincoGallery = () => {
    const [filterKey, setFilterKey] = useState('*');

    const handleFilterKeyChange = (key) => {
        setFilterKey(key);
    };

    const filteredData = filterKey === '*'
        ? cincoGalleryData
        : cincoGalleryData.filter(data => data.tag === filterKey);

    return (
        <section className="projects packery bg-dark-200">
            {/* <ScrollToTopButton/> */}
            <div className="container pt-5">
                <div className="section-header text-center">
                    <h6 className="text-white text-uppercase mb-3 mt-5">
                        Galería de fotos
                    </h6>
                    <p className="text-gray-600">Queremos mostrarte los mejores momentos
                        que han hecho nuestro camino más divertido
                    </p>
                </div>

                <div className="portfolio-filters-content mt-5">
                    <div className="filters-button-group">
                        <button className="button" onClick={() => handleFilterKeyChange('*')}>Todo <sup className="filter-count"></sup></button>
                        <button className="button" onClick={() => handleFilterKeyChange('improshow')}>Impro Show <sup className="filter-count"></sup></button>
                        <button className="button" onClick={() => handleFilterKeyChange('minutosfama')}>5 Minutos de Fama <sup className="filter-count"></sup></button>
                        <button className="button" onClick={() => handleFilterKeyChange('improplus')}>Impro Plus<sup className="filter-count"></sup></button>
                        <button className="button" onClick={() => handleFilterKeyChange('cincomediantes')}>Cincomediantes <sup className="filter-count"></sup></button>
                    </div>
                </div>

                <div className="grid gutter-20 clearfix image_holder" id="gallery">
                    <div className="grid-sizer"></div>
                    {
                        filteredData.map(data => (
                            <GalleryCard
                                key={data.id}
                                data={data} />
                        ))
                    }
                </div>
            </div>
        </section>
    )
};
export default CincoGallery;